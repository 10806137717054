import React, { useEffect, useState, useRef } from "react"

const ScrollSection = props => {
  function getCoords(elem) {
    var box = elem.getBoundingClientRect()

    var body = document.body
    var docEl = document.documentElement
    var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
    var clientTop = docEl.clientTop || body.clientTop || 0
    var top = box.top + scrollTop - clientTop
    var bottom = box.bottom + scrollTop - clientTop

    return { top: Math.round(top), bottom: Math.round(bottom) }
  }

  const scrollHandler = () => {
    const { top, bottom } = getCoords(containerRef.current)
    const ratio = (window.scrollY - top) / (bottom - top)
    setScrollRatio(ratio)
  }

  const containerRef = useRef()
  const [scrollRatio, setScrollRatio] = useState(0)

  useEffect(() => {
    document.addEventListener("scroll", scrollHandler)

    return () => {
      document.removeEventListener("scroll", scrollHandler)
    }
  }, [])
  return (
    <>
      <div
        ref={containerRef}
        className="globe"
        style={{
          background:
            props.direction === "right"
              ? `linear-gradient(135deg, rgba(0,0,0,0.15) 0%, rgba(255,255,255,0.15) ${
                  (scrollRatio + 0.7) * 100
                }%)`
              : `linear-gradient(135deg, rgba(255,255,255,0.15) 0%, rgba(0,0,0,0.05) ${
                  100 - (scrollRatio + 0.2) * 100
                }%)`,
        }}
      >
        {props.children}
      </div>
    </>
  )
}

export default ScrollSection
